/* eslint-disable react/jsx-filename-extension */
import '@chegg-wt/core/theme/form.scss'
import '@chegg-wt/core/theme/_index.scss'

import NextApp from 'next/app'
import { withRouter } from 'next/router'
import React from 'react'
import App from '@chegg-wt/core/components/App'
import createStore from '@chegg-wt/core/state'
import { Provider } from 'react-redux'
import cookies from 'next-cookies'
import {
  shouldHideAds,
  getAdConfigVersion,
  getError,
} from '@chegg-wt/core/utils/adConfig'
import { isServerSideContext } from '@chegg-wt/core/utils/isServerSideContext'
import { getActualStyleId, isStyleNonPrimary } from '@chegg-wt/core/utils/styles'
import { getBackendOptsWithOptimizelySafe } from '@chegg-wt/core/utils/backendOpts'
import { getUserPreloadedState } from '@chegg-wt/core/utils/user'
import { trackWithoutThrow } from '@chegg-wt/core/utils/errors'
import { getCurrentProjectPreloadedState } from '@chegg-wt/core/utils/currentProject'
import MigrationNotificationBanner from '@chegg-wt/core/components/MigrationNotificationBanner'
import DeIndex from '@chegg-wt/core/components/Head/DeIndex'
import { isNonCanonicalRoute } from '@chegg-wt/core/utils/url'
import siteEnv from '../state/env'
import { routes } from '../config/routes'
import { head } from '../config/head'
import { navItemsSelector } from '../config/nav'
import { footerLinkColumns } from '../copy/footerLinkColumns'
import { legalContents } from '../copy/legalContents'
import { BMTheme } from '../theme'
import {
  chooseGetSourceFromQuery,
  chooseGetStyleFromQuery,
  isSourceExact,
  getStyleAndSourceFromPathname,
} from '../utils/routes'
import { exportTabIds } from '../config/exportTab'

const makeStore = (preloadedState = {}) => {
  return createStore(routes, siteEnv, preloadedState)
}

class CitationsApp extends NextApp {
  static async getInitialProps({ Component, ctx }) {
    const hideAds = shouldHideAds(ctx, routes)
    const adConfigVersion = getAdConfigVersion({ ctx, hideAds })
    const pageProps = Component.getInitialProps
      ? await Component.getInitialProps(ctx)
      : {}
    const featureFlags = isServerSideContext(ctx)
      ? await getBackendOptsWithOptimizelySafe(ctx)
      : {}

    const { userRole } = cookies(ctx)
    const preloadedState = {
      featureFlags,
      user: getUserPreloadedState(userRole),
    }

    return { pageProps, hideAds, adConfigVersion, preloadedState }
  }

  componentDidMount() {
    const { adConfigVersion, hideAds } = this.props

    const error = getError(adConfigVersion, hideAds)
    if (error) {
      trackWithoutThrow(new Error(error))
    }
  }

  render() {
    const { Component, pageProps, preloadedState, router } = this.props

    const getSourceFromQuery = chooseGetSourceFromQuery(router)
    const getStyleFromQuery = chooseGetStyleFromQuery(router)

    const currentProject = getCurrentProjectPreloadedState({
      router,
      getActualStyleId,
    })
    const store = makeStore({ ...preloadedState, currentProject })
    const config = {
      head,
      routes,
      theme: BMTheme,
      exportTabIds,
      navItemsSelector,
      notificationBanner: MigrationNotificationBanner,
      footerContents: {
        footerLinkColumns,
        legalContents,
      },
      getSourceFromQuery,
      getStyleFromQuery,
      isSourceExact,
      getActualStyleId,
    }

    const getPageLayout = Component.getPageLayout || ((page) => page)

    const getSeoComponent = (router) => {
      const { asPath: path, route } = router
      const { style } = getStyleAndSourceFromPathname(path)
      const isNonPrimaryStyle = isStyleNonPrimary(getActualStyleId(style))
      const isNonCanonical = isNonCanonicalRoute(route)
      if (isNonPrimaryStyle || isNonCanonical) return <DeIndex />
    }

    return (
      <Provider store={store}>
        {getSeoComponent(this.props.router)}
        <App {...config}>{getPageLayout(<Component {...pageProps} />, pageProps)}</App>
      </Provider>
    )
  }
}

export default withRouter(CitationsApp)
