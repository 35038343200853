import { configureSegment } from '@chegg-wt/core/utils/routes'
import { getSegmentFn, getIsExactFn, getSpecialSegmentFn } from '@chegg-wt/core/utils/url'
import { GetFromQuery, IsExact } from '@chegg-wt/core/types/core'
import { getActualStyleId } from '@chegg-wt/core/utils/styles'

export const matchSourceSegment = configureSegment(':source+-citation')

export const matchSpecialSegment = configureSegment('/:style/:source-citation')

export const sourceSegmentRoutes = [
  '/[style]/[source]',
  '/[style]/[source]/search',
  '/[style]/[source]/confirm',
  '/[style]/[source]/new',
  '/[style]/[source]/custom',
  '/[style]/[source]/edit',
]

export const specialSegmentRoutes = ['/apa/website-citation']

export const getSourceFromQuery: GetFromQuery = getSegmentFn({
  segmentRoutes: sourceSegmentRoutes,
  matchSegment: matchSourceSegment,
  paramName: 'source',
})

export const isSourceExact: IsExact = getIsExactFn({
  segmentRoutes: sourceSegmentRoutes,
  matchSegment: matchSourceSegment,
})

const determineSpecialSegmentRoutes = (asPath: string) =>
  specialSegmentRoutes.some((route) => route === asPath)

const getSpecialSourceFromQuery = getSpecialSegmentFn({
  segmentRoutes: specialSegmentRoutes,
  matchSegment: matchSpecialSegment,
  paramName: 'source',
})

export const chooseGetSourceFromQuery = (router) => {
  const { route } = router
  const isSpecialSegmentRoute = determineSpecialSegmentRoutes(route)
  return isSpecialSegmentRoute ? getSpecialSourceFromQuery : getSourceFromQuery
}

const getSpecialStyleFromQuery: GetFromQuery = (router, _query, wrapByAlias = true) => {
  const matchedValue = getSpecialSegmentFn({
    segmentRoutes: specialSegmentRoutes,
    matchSegment: matchSpecialSegment,
    paramName: 'style',
  })(router)

  return wrapByAlias ? getActualStyleId(matchedValue) : matchedValue
}

export const chooseGetStyleFromQuery = (router) => {
  const { asPath } = router
  const isSpecialSegmentRoute = determineSpecialSegmentRoutes(asPath)
  return isSpecialSegmentRoute ? getSpecialStyleFromQuery : undefined
}

/**
 * function will take path as a string and will return the source and style components of it
 * @param pathString i.e /apa/encyclopedia-citation/confirm
 * @returns {style, source} i.e {style: 'apa', source: 'encyclopedia-citation'}
 */
export function getStyleAndSourceFromPathname(
  pathString: string
): { style: string; source: string } {
  const [, style, source] = pathString.split('/')

  return {
    style,
    source,
  }
}
