import { HeadTags } from '@chegg-wt/core/types/core'

export const head: HeadTags = {
  // title should eventually be changed and made page specific instead of app specific
  title: 'BibMe: Free Bibliography & Citation Maker - MLA, APA, Chicago, Harvard',
  metaTags: [
    {
      name: 'description',
      content: 'BibMe Free Bibliography & Citation Maker - MLA, APA, Chicago, Harvard',
    },
  ],
}
